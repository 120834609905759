<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-assignors' }">{{
            $t("MENU.ITEM.ASL.ASSIGNORS")
            }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:body>

        <b-form>
          <FormBuilder
                  v-if="!firstLoader"
                  :form="form"
                  :schemaJson="schemaJson"
                  :v="$v"

          ></FormBuilder>


        </b-form>
      </template>
      <template v-slot:foot>
        <div
                v-show="_.size(errors) > 0"
                role="alert"
                class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
                  :defaultAction="'continue'"
                  :isLoading="isLoadingProvider"
                  v-on:save="onSave($event)"

          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

  import { FETCH_ASSIGNORSGROUPS } from "@/modules/assignorsgroups/store/assignorsgroups.module";


  import FormBuilder from "@/view/content/forms/FormBuilder.vue";
  import FormInput from "@/view/content/forms/components/FormInput.vue";
  import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

  import { validationMixin } from "vuelidate";

  import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

  import KTCard from "@/view/content/Card.vue";
  import { mapGetters, mapState } from "vuex";
  import {FETCH_ASSIGNOR} from "../store/assignors.module";
  import {UPDATE_ASSIGNOR} from "../store/assignors.module";

  export default {
    mixins: [validationMixin, formBuilderMixin],
    data() {
      return {
        errors: {},
        assignorgroup: [],
        id: null
      };
    },
    components: {
      KTCard,
      FormBuilder,
      SaveButtonDropdown,
      FormInput
    },
    mounted() {
      let vm = this;
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: this.$i18n.t('MENU.ITEM.ASL.ASSIGNORS'), route: { name: "list-assignors" } },
        { title: this.$i18n.t('MENU.ITEM.EDIT')}
      ]);



      let id = this.$route.params.id;
      this.$store.dispatch(FETCH_ASSIGNOR, id).then((data)=>{
        this.id = data.data.id
        this.generateFormOutOfSchemaJson(this.schemaJson, data.data);
      })
      this.$store.dispatch(FETCH_ASSIGNORSGROUPS).then((data)=>{
        this.assignorgroup = data.data.items
      })
    },
    computed: {
      ...mapGetters([
        "isLoadingAssignorgroup",
        "isLoadingSharedStore"
      ]),
      ...mapState({
        errors: state => state.assignors_errors
      }),

      schemaJson() {
        return {
          fields: [
            {
              type: "input",
              inputType: "text",
              id: "provider-name-input",
              groupId: "provider-name-group",
              required: "required",
              feedback_id: "provider-live-feedback",
              divider: false,
              i18n: {
                label: "FORM_LABELS.NAME",
                placeholder: "FORM_PLACEHOLDERS.NAME",
                validation: "FORM.VALID_NAME"
              },
              validations: {
                required: true,
                minLength: 2
              },
              translatable: false,
              model: "companyName"
            },
            {
              type: "select",
              inputType: "select",
              groupId: "item-unit-group",
              id: "item-unit-input",
              feedback_id: "unit-live-feedback",
              divider: false,
              clearable: false,
              i18n: {
                label: "FORM_LABELS.GROUP",
                placeholder: "FORM_PLACEHOLDERS.UNIT"
              },
              options: this.assignorgroup,
              validations: {
                required: true
              },
              model: "assignorsGroupsId"
            },
          ]
        };
      }
    },
    validations() {
      let vm = this;
      let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
              this.schemaJson
      );
      return tmpValidationObject;
    },
    methods: {
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },
      onSubmit(evt) {
        evt.preventDefault();
      },
      onSave(next) {
        let vm = this;
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          vm.$notify({
            group: "notify",
            type: "error",
            title: "<i class='flaticon-close'></i> Warning",
            text: "Please fill all required fields correctly"
          });
          return;
        }
        this.$store
                .dispatch(UPDATE_ASSIGNOR, { id: this.id, payload: this.form })
                .then(data => {
                  vm.errors = data.data.errors;
                  let itemEdit = data.data;
                  vm.$notify({
                    group: "notify",
                    type: "success",
                    title: "<i class='flaticon2-checkmark'></i> Success",
                    text: data.message
                  });
                  // let itemEdit = data.data;
                  if (next == "continue") {
                    //New


                  }
                  if (next == "new") {
                    //New
                    this.$router.push({ name: "add-assignors" });
                  }
                  if (next == "exit") {
                    this.$router.push({ name: "list-assignors" });
                  }
                })
                .catch((response) => {
                  vm.errors = response.data.errors;
                  vm.$notify({
                    group: "notify",
                    type: "error",
                    title: this.$t("ALERTS.ERROR"),
                    text: response.data.message
                  });
                });
      },
      resetVForm() {
        this.$v.$reset();
      }
    }
  };
</script>
